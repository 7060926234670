<template>
    <div class="wrap">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <p>{{ $t('Public.ControlService.banner.title') }}</p>
            </div>
            <div class="content">
                <p class="detail_title">{{ $t('Public.ControlService.control.title') }}</p>
                <div class="flex contorl_detail">
                    <ul>
                        <li v-for="(item, index) in $t('Public.ControlService.control.list')" :key="index">
                            <div class="contorl_detail_title">
                                <div class="control_icon"><img :src="require('../../public/images/service/control/control_icon' + (index + 1) + '.png')" /></div>
                                <p>{{ item.title }}</p>
                            </div>
                            <span>{{ item.text }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="galileo">
                <p class="detail_title">Galileo-Mobility Platform</p>
                <p class="detail_title" v-if="$i18n.locale == 'CN'">驾安安平台</p>
                <div class="galileo_introduce" v-show="0">
                    {{ $t('Public.UBIService.galileo.galileoIntro') }}
                </div>
                <ul>
                    <li v-for="(item, index) in $t('Public.UBIService.galileo.list')" :key="index">
                        <div class="galileo_img">
                            <img :src="require('../../public/images/service/ubi/galileo_icon' + (index + 1) + '.png')" />
                        </div>
                        <p>{{ item }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';

export default {
    name: 'ControlService',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {};
    },
    methods: {},
    mounted() {}
};
</script>
<style scoped>
.contorl_detail {
    margin-top: 0.5rem;
}
.contorl_detail li {
    padding: 0.5rem 0;
    border-bottom: 1px dashed #d8d8d8;
}
.contorl_detail li:last-child {
    border-bottom: none;
}
.contorl_detail li span {
    font-weight: 400;
    color: #666666;
    font-size: 0.24rem;
    line-height: 1.5;
    display: inline-block;
    padding-left: 1.3rem;
    text-align: justify;
}
.contorl_detail_title {
    display: flex;
    align-items: center;
    font-size: 0.34rem;
    font-weight: 600;
    margin-bottom: 0.04rem;
}
.control_icon {
    width: 1rem;
    margin-right: 0.3rem;
}
.banner {
    width: 100%;
    height: 4.4rem;
    padding: 1.88rem 0 0 0.77rem;
    background: url(../../public/images/service/control/banner.jpg) no-repeat;
    background-size: 100% 100%;
    background-color: #0c1f3d;
}
.banner p {
    font-size: 0.46rem;
    line-height: 1.39;
    color: #fff;
}
.content {
    padding: 0.5rem 0.2rem;
    color: #333;
}
.detail_title {
    font-size: 0.42rem;
    font-weight: 500;
    text-align: center;
}
.galileo {
    padding: 0.7rem 0.27rem 0.96rem;
}
.galileo ul {
    width: 5.26rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 0.3rem;
    margin: 0 auto;
    margin-top: 1rem;
}
.galileo li {
    width: 2rem;
    margin-bottom: 0.78rem;
    text-align: center;
}
.galileo_introduce {
    line-height: 1.71;
    margin: 0.7rem 0 0.6rem;
    text-align: justify;
}
.wrap_en .galileo_introduce {
    text-align: left;
    text-align: justify;
}
.galileo_img {
    width: 2rem;
    margin-bottom: 0.2rem;
}
</style>
